<template>
  <b-modal
    :id="id"
    v-model="show"
    :no-close-on-backdrop="closeOnBackdrop"
    centered
    :hide-footer="hideFooter"
    :hide-header="hideHeader"
    @hidden="$emit('hidden')"
    @ok="$emit('ok', $event)"
    :title="title"
    :footer-class="footerClass"
  >
    <slot name="content"></slot>
  </b-modal>
</template>

<script>
export default {
  props: {
    closeOnBackdrop: { required: false, type: Boolean },
    hideFooter: { required: false, type: Boolean },
    hideHeader: { required: false, type: Boolean },
    title: { required: false, type: String },
    id: { required: false, type: String },
    footerClass: { required: false, type: String },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },
};
</script>

<style></style>
